import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap'
import { getVideoArsipSingle } from '../Auth/apiAdmin';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import FooterCopyright from '../components/FooterCopyright'


const VideoArsipSingle = props => {

    const [video, setVideo] = useState({})
    const [ setError] = useState('')

    const loadSingleVideo = videoarsipId => {
        getVideoArsipSingle(videoarsipId).then(data => {
            if(data.error) {
                setError(data.error)
            } else {
                setVideo(data)
            }
        })
    }

    useEffect(() => {
        const videoarsipId = props.match.params.videoarsipId
        loadSingleVideo(videoarsipId)
    }, [])
    
    return (
        <>
        <NavbarHome/>
        <div id='main'>


            <div className='container'>
            <div className='row'>
                  
                <div className='col-md-9'>
            
                
                <br/>
                    <div className="box">
                        <div className='image fit'> 
                            <iframe title='videoyoutube' width="100%" height="400px" src={video.linkyoutube} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                        
                        </div>
                        <div className="inner color-black ">
                        <div className='row'>
                            <div className='col-md-4'>
                                <h3 style={{textAlign:'justify'}}>Judul Tarian:</h3>
                            </div>
                            <div className='col-md-8'>
                                <p style={{textAlign:'justify'}}>{video.judul}</p>

                            </div>
                            <div className='col-md-4'>
                                <h3 style={{textAlign:'justify'}}>Konsep Singkat:</h3>
                            </div>
                            <div className='col-md-8'>
                                <p style={{textAlign:'justify'}}>{video.konsep}</p>

                            </div>

                            <div className='col-md-4'>
                                <h3 style={{textAlign:'justify'}}>Koreografer:</h3>
                            </div>
                            <div className='col-md-8'>
                                <p style={{textAlign:'justify'}}> {video.koreografer}</p>
                            </div>

                            <div className='col-md-4'>
                                <h3 style={{textAlign:'justify'}}>Penari:</h3>
                            </div>
                            <div className='col-md-8'>
                                <p style={{textAlign:'justify'}}> {video.penari}</p>
                            </div>

                            <div className='col-md-4'>
                                <h3 style={{textAlign:'justify'}}>Musik Pengiring:</h3>
                            </div>
                            <div className='col-md-8'>
                                <p style={{textAlign:'justify'}}> {video.musik}</p>
                            </div>

                        </div>
                       
                        </div>
                    </div>
                </div>
            

                <div className='col-md-3 text-center mt-10'>
                    
                    <br/>
                    
                    <a href='/galeriarsip'>
                        <Button style={{width:'100%'}} className='btn btn-secondary mb-4 '>Lihat Video Lainnya</Button>
                    
                    </a>                   
                    

                </div>

            </div>
            </div>
        </div>
        <FooterHome/>
        <FooterCopyright/>
    </>
    )
}

export default VideoArsipSingle;