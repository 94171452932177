import React from 'react';
import { Nav, Navbar, Button} from 'react-bootstrap'
import { withRouter, Link} from 'react-router-dom'
import { logout, isAuthenticated} from '../Auth/index'
import logoidn from '../assets/img/logoidn.png'

const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "#007bff" };
    } else {
        return { color: "#636e72" };
    }
};

const NavbarHome = ({history}) => {
    return (
        <div style={{paddingTop:'15px', paddingBottom:'15px'}}>
       
        <Navbar bg="light" variant="light" expand="md" fixed="top">
            <div className='container-sm' >
            <Navbar.Brand href='/'><img src={logoidn} style={{ height:'40px' }} alt='logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            
            </Nav>
            <Nav className="mr-right">
              
            <Link to='/' style={isActive(history, "/")} >Home</Link>
            <Link to='/galeri' style={isActive(history, "/galeri")}>Video Saweran</Link>
                <Link to='/galeriarsip' style={isActive(history, "/galeriarsip")}>Galeri Video</Link>
                <Link to='/about' style={isActive(history, "/about")}>Visi & Misi</Link>
                
              
                
                {isAuthenticated() && isAuthenticated().user.role === 1 && (
                   
                    <Link to='/admin/dashboard' style={isActive(history, "/admin/dashboard")} >Dashboard</Link>
                )}

                {isAuthenticated() && (
                    
                    <Link><span onClick={()=> logout(()=> {
                        history.push('/')
                    })}>Logout</span></Link>
                   
                )}


                
            </Nav>
            
            </Navbar.Collapse>
            </div>
        </Navbar>
        
       
        </div>
    )
}

export default withRouter(NavbarHome);