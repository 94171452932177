import React from 'react';

const AboutHalaman = () => {

    return (
        <div>
        <section id="about-2">
            <div class="container">
                <div className='row abot-margin-3 color-white'>
                <div className='col-md-12'>
                <div className='color-white text-center pb-4'>
                     <h1>Visi & Misi </h1>
                     <p>Indonesia Dance Network (IDN) merupakan platform jejaring digital bagi seniman tari di Indonesia</p>
                </div>
                <div className='row'>
                     <div className='col-md-6'>
                         <div className='color-white text-justify'>
                         <h3 className='text-center pb-3'>Visi</h3>
                         <p>Sebagai sebuah jejaring digital  yang tidak lagi mengenal batas fisik, IDN adalah sebuah medium yang efektif untuk menghubungkan para pelaku seni tari di berbagai daerah di Indonesia dengan masyarakat pencinta tari. Pusat arsip dari keragaman karya-karya tari yang diproduksi para pelaku seni tari dari berbagai pelosok daerah di seluruh Indonesia.</p>
                         </div>
                     </div>

                     <div className='col-md-6'>
                         <div className='color-white text-justify'>
                            

                            <h3 className='text-center pb-3'>Misi</h3>
                            <ul style={{ textAlign:'justify', fontSize:'16px' }}>
                               <li className='pb-2'>Untuk memperkuat visibiltas profesi seniman tari dan karya-karya tari ke masyarakat yang lebih luas.</li>
                               <li className='pb-2'>Untuk memperluas pengetahuan dan informasi mengenai keberagaman tari yang ada di Indonesia.</li>
                               <li className='pb-2'>Menjalin keterhubungan yang lebih luas antar pelaku tari yang ada di Indonesia dengan masyarakat pencinta tari.</li>
                           </ul>
                          
                         </div>
                     </div>
                 </div>

                               


             </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default AboutHalaman;