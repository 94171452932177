import React, { useState} from 'react';
import { isAuthenticated } from '../Auth/'
import { createBerita } from '../Auth/apiAdmin'
import {Link } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';


const AddBerita = () => {
    const [values, setValues] = useState({
        judulberita: '',
        isiberita: '',
        error: '',
        success: false
    })


    const handleChangeBerita = judulberita => event => {
        setValues({...values, [judulberita]: event.target.value })

    }

    const {judulberita, isiberita, success, error} = values

    const { user, token } = isAuthenticated();

    const clickSubmitBerita = (event) => {
        event.preventDefault()
        setValues({...values})

        const nambahberita = {
            judulberita ,
            isiberita 
        }

        // make request to api to create Berita
        createBerita( user._id, token, nambahberita )
        .then(data => {
            if(data.error) {
                setValues({
                    ...values, 
                    error: data.error, 
                    success: false
                })
            } else {
                setValues({
                    ...values,
                    judulberita: "",
                    isiberita: "",
                    success: true
                })
            }
        });
    }


    const newBeritaForm = () => (
        <form onSubmit={clickSubmitBerita}>
            <div className='form-group'>
                <label className='color-white'>Judul Berita</label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChangeBerita('judulberita')} 
                    value={judulberita}
                    required
                />
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChangeBerita('isiberita')} 
                    value={isiberita}
                    required
                />
                

                <button style={{ width:'150px' }}  className='btn btn-sm btn-success mr-4'> Tambah </button>
                <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-sm btn-danger'> Kembali ke Admin </button></Link>

                
                
                
            </div>
        </form>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">Berita Berhasil ditambahkan!! <Link className='color-red' to='/admin/dashboard/categories'>Lihat Semua Berita</Link> </h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger">Berita Sudah ada Bro</h3>;
        }
    };

    return (
        <div>
        <NavbarHome/>
            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Silahkan Menambahkan Nama Berita</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'500px' }} id='main'>
                <div className='container'>
                {showSuccess()}
                {showError()}
                {newBeritaForm()}
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default AddBerita;