import { API } from '../config'
import queryString from 'query-string'


export const createCategory = (userId, token, namaKategori) => {
    return fetch(`${API}/category/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(namaKategori)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCategories = () => {
    return fetch(`${API}/categories`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}


export const deleteCategory = (categoryId, userId, token ) => {
    return fetch(`${API}/category/delete/${categoryId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};



// crud product

export const createProduct = (userId, token, nambahvideo) => {
    return fetch(`${API}/product/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(nambahvideo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const getProductVideo = (sortBy) => {
    return fetch(`${API}/allproduct?sortBy=${sortBy}&order=desc`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}

export const getProductForHome = (sortBy) => {
    return fetch(`${API}/allproducthome?sortBy=${sortBy}&order=desc&limit=6`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}

export const getProductSingle = (productId) => {
    return fetch(`${API}/video/${productId}`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}

export const deleteProduct = (productId, userId, token, ) => {
    return fetch(`${API}/product/delete/${productId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const editProduct = (productId, userId, token, editproduct) => {
    return fetch(`${API}/product/edit/${productId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(editproduct)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};



export const getFilterProduct = (filters = {}) => {
    const data = {
        filters
    }
    return fetch(`${API}/product/by/search`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const listSearch = params => {
    const query = queryString.stringify(params);
    console.log("query", query);
    return fetch(`${API}/products/search?${query}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};







// berita crud
export const createBerita = (userId, token, nambahberita) => {
    return fetch(`${API}/berita/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(nambahberita)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};



// VIDEO ARSIP API CALL

export const getVideoArsip = (sortBy) => {
    return fetch(`${API}/allvideoarsip?sortBy=${sortBy}&order=desc`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}


export const createVideoArsip = (userId, token, nambahVideoArsip) => {
    return fetch(`${API}/videoarsip/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(nambahVideoArsip)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getVideoArsipSingle = (videoarsipId) => {
    return fetch(`${API}/videoarsip/${videoarsipId}`, {
        method: 'GET'
    }) 
        .then(response => {
            return response.json();
        }) 
        .catch(err => console.log(err));
}

export const editVideoArsip = (videoarsipId, userId, token, editvideoarsip) => {
    return fetch(`${API}/videoarsip/edit/${videoarsipId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(editvideoarsip)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteVideoArsip = (videoarsipId, userId, token, ) => {
    return fetch(`${API}/videoarsip/delete/${videoarsipId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};