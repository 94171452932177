import React from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import GaleriArsip from '../components/GaleriArsip';
import FooterCopyright from '../components/FooterCopyright';

const PageGaleriArsip = () => {

    return (
        <div>
           <NavbarHome/>
           <GaleriArsip/>
           <FooterHome/>
           <FooterCopyright/>
        </div>
    )
}

export default PageGaleriArsip;