import React from 'react';
import LandingPage from './pages/Landing';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import GaleryPage from './pages/GaleryPage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdminPage from './pages/AdminPage';
import VideoPage from './pages/VideoPage';
import AddCategory from './admin/AddCategory';
import ShowCategory from './admin/ShowCategory';
import AddVideo from './admin/AddVideo';
import AddBerita from './admin/AddBerita';
import ShowVideo from './admin/ShowVideo';
import SuccessPage from './pages/SucessPage';
import EditVideo from './admin/EditVideo';
import SuccessEditVideo from './pages/SuccessEditVideo';
import AdminRoute from './Auth/AdminRoute'
import ErrorPage from './pages/404page';
import PageGaleriArsip from './pages/VideoArsip';
import ShowVideoArsip from './admin/ShowVideoArsip';
import AddVideoArsip from './admin/AddVideoArsip';
import EditVideoArsip from './admin/EditVideoArsip';
import VideoArsipSingle from './pages/VideoArsipPage';

function App() {
  return (
   <div>
   <BrowserRouter>
      <Switch>
        <Route path='/' exact component={LandingPage} />
        <Route path='/galeri' exact component={GaleryPage} />
        <Route path='/galeriarsip' exact component={PageGaleriArsip} />
        <Route path='/success' exact component={SuccessPage} />
        <Route path='/successeditvideo' exact component={SuccessEditVideo} />
        <Route path='/about' exact component={AboutPage} />
        <Route path='/video/:videoId' exact component={VideoPage} />
        <Route path='/videoarsip/:videoarsipId' exact component={VideoArsipSingle} />
        <Route path='/login/admin' exact component={LoginPage} />
        <Route path='/register/admin' exact component={RegisterPage} />
        <AdminRoute path='/admin/dashboard' exact component={AdminPage} />
        <AdminRoute path='/admin/dashboard/crate/category' exact component={AddCategory} />
        <AdminRoute path='/admin/dashboard/categories' exact component={ShowCategory} />
        <AdminRoute path='/admin/dashboard/create/video' exact component={AddVideo} />
        <AdminRoute path='/admin/dashboard/edit/video/:productId' exact component={EditVideo} />
        <AdminRoute path='/admin/dashboard/allvideo' exact component={ShowVideo} />
        <AdminRoute path='/admin/dashboard/crate/berita' exact component={AddBerita} />
        <AdminRoute path='/admin/dashboard/allvideoarsip' exact component={ShowVideoArsip} />
        <AdminRoute path='/admin/dashboard/create/videoarsip' exact component={AddVideoArsip} />
        <AdminRoute path='/admin/dashboard/edit/videoarsip/:videoarsipId' exact component={EditVideoArsip} />

        
        <Route path='*' exact component={ErrorPage} />
      </Switch>
    </BrowserRouter>
 
   </div>
  );
}

export default App;
