import React, {useState, useEffect} from 'react';
import { getCategories, deleteCategory } from '../Auth/apiAdmin'
import {Link } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';
import { Button, Table } from 'react-bootstrap'
import { isAuthenticated } from '../Auth';

const ShowCategory = () => {
    const [dataKategori, setDataKategori] = useState([]);
    const [ setError] = useState("");
    
   
    const loadDataKategori = () => {
        getCategories().then(data => {
            if(data.error) {
                setError(data.error)
            } else {
                setDataKategori(data)
            }
        })
    }

    const { user, token } = isAuthenticated()

    const removeCategory = categoryId => {
        deleteCategory(categoryId, user._id, token ).then(data => {
            if(data.error) {
                console.log(data.error)
            } else {
                loadDataKategori()
            }
            alert('yakin mau delete?')
        })
    }

    useEffect(() => {
        loadDataKategori()
    },[])

   
    return (
        <div>
        <NavbarHome/>
            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Semua List Kategori</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'fit-content' }} id='main'>
                <div className='container'>
                    <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-sm btn-danger mb-4'> Kembali ke Admin </button></Link>
                    <div className='card mb-5'>

                        <Table striped bordered hover>
                            <thead style={{color:'black'}} >
                                <tr>
                                    <th style={{width:'50px'}}>No</th>
                                    <th>Kategori</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {dataKategori.map((kat, i) => (
                                    <tr key={i} >
                                    <td>{i+1}</td>
                                    <td>{kat.namaKategori}</td>
                                    <td>
                                        <Button style={{width:'100px'}} className='btn btn-info btn-sm mr-2'>Edit</Button>
                                        <Button onClick={() => removeCategory(kat._id)}  style={{width:'100px'}} className='btn btn-danger btn-sm'>Delete</Button>
                                    </td>
                                </tr>
                                ))}
                                
                                
                               
                               
                                
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default ShowCategory;