import React from 'react';
import { Carousel } from 'react-bootstrap'
import imglogo from '../assets/img/logoputih.png'
import imglogo2 from '../assets/img/logosaweran.png'


const SlideHome = () => {

    return (
        <div>
        
        <Carousel>
        <Carousel.Item className='slide1'>
           <div className='container'>
                <div className='captionhome'>
                    <img src={imglogo} alt='logoidn'/>
                </div>
           </div>
        </Carousel.Item>
        <Carousel.Item className='slide2'>
            <div className='container'>
                <div className='captionhome2'>
                    <img src={imglogo2} alt='logosaweran'/>
                </div>
            </div>
        </Carousel.Item>
        </Carousel>
        
        </div>
    )
}

export default SlideHome;