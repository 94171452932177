import React from 'react';
import imglogo from '../assets/img/logoidn.png'
import dkjlogo from '../assets/img/dkj-logo.png'
import kemendikbudlogo from '../assets/img/logokemendikbud.png'

const About = () => {

    return (
        <div>
        <section id="about">
            <div className="container">
                <div className='row abot-margin'> 
                    <div className='col-md-4'>
                        <img src={imglogo} alt='img' className='imglogologo' />
                    </div>

                    <div className='col-md-8 color-black text-justify'>
                 
                    <br/>
                   <p>Indonesia Dance Network (IDN) merupakan platform jejaring digital bagi seniman tari di Indonesia, yang diinisiasi oleh Komite Tari Dewan Kesenian Jakarta (DKJ) dan didukung oleh kemendikbud. Selain berfungsi sebagai panggung digital, IDN turut mengupas beragam khasanah seni tari yang ada di Indonesia lebih mendalam. IDN bertujuan sebagai ruang apresiasi bagi masyarakat pecinta seni tari dari berbagai genre.</p>
    
                    <p>Salah satu kegiatan dari IDN adalah saweran online dimana seniman tari menampilkan karya atau tutorial seni tari, kemudian masyarakat dapat langsung memberikan donasi apresiasi. Pada masa pandemik ini sebagian dari donasi apresiasi tersebut disumbangkan untuk covid 19 sebagai bentuk kontribusi seniman tari terhadap bencana ini.</p>

                    <p>Proyek ini merupakan hasil kerjasama Kementerian Pendidikan dan Kebudayaan RepubIik Indonesia, dan Komite Tari Dewan Kesenian Jakarta. </p>
    
                    <ul className="icons text-center">
                    <li><img src={kemendikbudlogo} alt='logo' style={{ height:'75px' }} /></li>
                    <li><img src={dkjlogo} alt='logo' style={{ height:'75px' }} /></li>
                    </ul>
                    
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default About;