import React, { useState} from 'react';
import { isAuthenticated } from '../Auth/'
import { createCategory } from '../Auth/apiAdmin'
import {Link } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';


const AddCategory = () => {
    const [namaKategori, setNamaKategori] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const { user, token } = isAuthenticated();

    const handleChangeCategory = (e) => {
        setError('')
        setNamaKategori(e.target.value)
    }

    const clickSubmitCategory = (e) => {
        e.preventDefault()
        setError('')
        setSuccess(false)

        // make request to api to create category
        createCategory(user._id, token, { namaKategori }).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setError("");
                setSuccess(true);
                setNamaKategori('')
            }
        });
    }


    const newCategoryForm = () => (
        <form onSubmit={clickSubmitCategory}>
            <div className='form-group'>
                <label className='color-white'>Nama Kategori</label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChangeCategory} 
                    value={namaKategori}
                    required
                />
                <button style={{ width:'150px' }}  className='btn btn-sm btn-success mr-4'> Tambah </button>
                <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-sm btn-danger'> Kembali ke Admin </button></Link>

                
                
                
            </div>
        </form>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">Kategori Berhasil ditambahkan!! <Link className='color-red' to='/admin/dashboard/categories'>Lihat Semua Kategori</Link> </h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger">Kategori Sudah ada Bro</h3>;
        }
    };

    return (
        <div>
        <NavbarHome/>
            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Silahkan Menambahkan Nama Kategori</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'500px' }} id='main'>
                <div className='container'>
                {showSuccess()}
                {showError()}
                {newCategoryForm()}
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default AddCategory;