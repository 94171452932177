import React, {useEffect, useState} from 'react';
import { isAuthenticated } from '../Auth/'
import { getVideoArsipSingle,  editVideoArsip } from '../Auth/apiAdmin'
import {Link, Redirect } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';


const EditVideoArsip = ({match}) => {
    
    const [values, setValues] = useState({
        judul: '',
        konsep: '',
        koreografer: '',
        penari:'',
        musik:'',
        linkyoutube:'',
        error: '',
        success: false,
        redirectSuccess:false
    })

    const {
        judul, 
        konsep, 
        koreografer, 
        penari, 
        musik, 
        linkyoutube,
        error,
        success,
        redirectSuccess

    } = values

    const { user, token } = isAuthenticated();

    


    const init = (videoarsipId) => {
        getVideoArsipSingle(videoarsipId).then(data => {
            if(data.error) {
                console.log(data.error)
            } else {
                setValues({...values,
                    judul: data.judul,
                    konsep : data.konsep,
                    koreografer: data.koreografer,
                    penari : data.penari,
                    musik : data.musik,
                    linkyoutube: data.linkyoutube
                   
                })
               
            }
        })
    }




    useEffect(() => {
        init(match.params.videoarsipId);
    },[]);


    const handleChange = judul => event => {
        setValues({...values, error: false, [judul]: event.target.value})
    }

    const clickSubmit = (event) =>{
        event.preventDefault()


        const editvideoarsip = {
            judul, 
            konsep, 
            koreografer, 
            penari, 
            musik, 
            linkyoutube
        }
        editVideoArsip( match.params.videoarsipId, user._id, token, editvideoarsip)
        .then(data => {
            if(data.error) {
                console.log(data.error)
            } else {
                setValues({
                    ...values,
                    judul: '',
                    konsep: '',
                    koreografer: '',
                    penari:'',
                    musik:'',
                    linkyoutube:'',
                    error: "",
                    success: true,
                    redirectSuccess: true
                })
                
            }
        })

    }


    const newVideoForm = () => (
    <>
        <form className='color-white'>
            <div className='form-group'>
                <label>Edit Judul </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('judul')} 
                    value={judul} />
            </div>

            <div className='form-group'>
                <label>Edit Konsep </label>
                <textarea 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('konsep')} 
                    value={konsep} />
            </div>

            <div className='form-group'>
                <label>Edit Koreografer </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('koreografer')} 
                    value={koreografer} />
            </div>

            <div className='form-group'>
                <label>Edit Penari </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('penari')} 
                    value={penari} />
            </div>

            <div className='form-group'>
                <label>Edit Musik </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('musik')} 
                    value={musik} />
            </div>

            <div className='form-group'>
                <label>Edit Link Yotube </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('linkyoutube')} 
                    value={linkyoutube} />
            </div>

        </form>

        <br/>

        <div style={{textAlign:'center'}} className='mb-5'>
            <button style={{ width:'150px' }} onClick={clickSubmit} className='btn btn-success mr-3' > Edit Video </button>
            <Link to='/admin/dashboard/allvideoarsip' ><button style={{ width:'200px' }}  className='btn btn-danger'> Kembali ke Video Arsip</button></Link>

        </div>

    </>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success" style={{display: success ? '' : 'none'}} >Video Berhasil ditambahkan!! <Link className='color-red' to='/admin/dashboard/allvideo'>Lihat Semua Video</Link> </h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger" style={{display: error ? '' : 'none'}}>Video Sudah ada Bro</h3>;
        }
    };

    const redirectTo = () => {
        if(redirectSuccess) {
            if(!error) {
                return  <Redirect to='/admin/dashboard/allvideoarsip' />
            }
        } 
             
    }

   
    return (
       
        <div>
        <NavbarHome/>
            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Silahkan Edit Video </h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'fit-content' }} id='main'>
                <div className='container'>
                {showError()}
                {showSuccess()}
                {newVideoForm()}
                {redirectTo()}
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default EditVideoArsip;