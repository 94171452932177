import React, {useEffect, useState} from 'react';
import { isAuthenticated } from '../Auth/'
import { createProduct, getCategories } from '../Auth/apiAdmin'
import {Link, Redirect } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';


const AddVideo = () => {
    
    const [values, setValues] = useState({
        judul: '',
        konsep: '',
        koreografer: '',
        penari:'',
        musik:'',
        linkyoutube:'',
        linksawer:'',
        category:'',
        categories:[],
        error: '',
        success: false,
        redirectSuccess:false
    })


    

    const {
        judul, 
        konsep, 
        koreografer, 
        penari, 
        musik, 
        linkyoutube,
        linksawer,
        category,
        categories,
        error,
        success,
        redirectSuccess

    } = values

    const { user, token } = isAuthenticated();

    const init = () => {
        getCategories().then(data => {
            if(data.error) {
                setValues({...values, error: data.error})
            } else {
                setValues({...values, categories: data})
            }
        })
    }


    useEffect(() => {
        init();
    }, []);


    const handleChange = judul => event => {
        setValues({...values, error: false, [judul]: event.target.value})
    }

    const clickSubmit = (event) =>{
        event.preventDefault()
        setValues({...values, error: false, success: false})

        const nambahvideo = {
            judul, 
            konsep, 
            koreografer, 
            penari, 
            musik, 
            linkyoutube,
            linksawer,
            category
        }
        createProduct( user._id, token, nambahvideo)
        .then(data => {
            if(data.error) {
                setValues({
                    ...values, 
                    error: data.error, 
                    success: false
                })
            } else {
                setValues({
                    ...values,
                    judul: '',
                    konsep: '',
                    koreografer: '',
                    penari:'',
                    musik:'',
                    linkyoutube:'',
                    linksawer:'',
                    category:'',
                    error: "",
                    success: true,
                    redirectSuccess: true
                })
                
            }
        })

    }


    const newVideoForm = () => (
    <>
        <form className='color-white'>
            <div className='form-group'>
                <label>Masukan Judul </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('judul')} 
                    value={judul} />
            </div>

            <div className='form-group'>
                <label>Masukan Konsep </label>
                <textarea 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('konsep')} 
                    value={konsep} />
            </div>

            <div className='form-group'>
                <label>Masukan Koreografer </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('koreografer')} 
                    value={koreografer} />
            </div>

            <div className='form-group'>
                <label>Masukan Penari </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('penari')} 
                    value={penari} />
            </div>

            <div className='form-group'>
                <label>Masukan Musik </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('musik')} 
                    value={musik} />
            </div>

            <div className='form-group'>
                <label>Masukan Link Yotube </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('linkyoutube')} 
                    value={linkyoutube} />
            </div>

            <div className='form-group'>
                <label>Masukan Link Sawer </label>
                <input 
                    type='text'  
                    className='form-control mb-4' 
                    onChange={handleChange('linksawer')} 
                    value={linksawer} />
            </div>

            <div className='form-group'>
                <label className='text-muted'>Category</label>
                <select
                    onChange={handleChange('category')} 
                    className='form-control' 
                >

                    <option>Pilih Category</option>  
                    {categories && categories.map((cat, i) => (
                        <option key={i} value={cat._id}>{cat.namaKategori}</option>  
                    ))}
                </select> 
            </div>

                    

        </form>

        <br/>

        <div style={{textAlign:'center'}} className='mb-5'>
            <button style={{ width:'150px' }} onClick={clickSubmit} className='btn btn-success mr-3' > Tambah Video </button>
            <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-danger'> Kembali ke Admin </button></Link>

        </div>

    </>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success" style={{display: success ? '' : 'none'}} >Video Berhasil ditambahkan!! <Link className='color-red' to='/admin/dashboard/allvideo'>Lihat Semua Video</Link> </h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger" style={{display: error ? '' : 'none'}}>Video Sudah ada Bro</h3>;
        }
    };

    const redirectTo = () => {
        if(redirectSuccess) {
            if(!error) {
                return  <Redirect to='/admin/dashboard/allvideo' />
            }
        } 
             
    }

   
    return (
       
        <div>
        <NavbarHome/>
            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Silahkan Menambahkan Video Baru</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'fit-content' }} id='main'>
                <div className='container'>
                {showError()}
                {showSuccess()}
                {newVideoForm()}
                {redirectTo()}
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default AddVideo;