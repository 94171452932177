import React from 'react';


const FooterCopyright = () => {

    return (
        <section id='fotercopyright'>
            <div className='container text-center'>
                <span className="copyright">&copy; 2020 Indonesia Dance Network. All Right Reserved.</span>

            </div>
        </section>
    )
}

export default FooterCopyright