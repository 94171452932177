import React, { useState, useEffect} from 'react';
import '../assets/css/main.css'
import { getProductForHome } from '../Auth/apiAdmin'
import { Button, Spinner } from 'react-bootstrap'

const GaleriHome = () => { 
  const [videoData, setVideoData] = useState([])

  const [loading, setLoading] = useState(false)

  const loadVideoData = () => {
    setLoading(true)
    getProductForHome('createdAt').then(data => {
        if(data.error) {
           console.log(data.error)
        } else {
            setVideoData(data)
            setLoading(false)
        }
    })
  } 

  useEffect(() => {
    loadVideoData()
  }, [])

  const showLoading = () => (
    loading && (
      <div className='container text-center color-black'>
      <h2>Wait a second, fetching data from server..</h2>
            <Spinner animation="grow" size='lg' variant="primary" />
            <Spinner animation="grow" size='lg' variant="secondary" />
            <Spinner animation="grow" size='lg' variant="success" />
            <Spinner animation="grow" size='lg' variant="danger" />
            <Spinner animation="grow" size='lg' variant="warning" />
            <Spinner animation="grow" size='lg' variant="info" />
      </div>
    )
  )


    return (
      
        <div id="about">
        <div className='container'>
        <div className="inner">
            <div className='headinggaleri color-black'>
            <h1>VIDEO SAWERAN ONLINE</h1>
            <p>Silahkan tonton  video tari gratis di bawah ini. Jika kamu suka, kamu bisa berikan dana apresiasimu.  
            </p>
            <span style={{ fontSize:'15px', textAlign:'justify', fontStyle:'italic', marginBottom:'20px' }}>Sebesar 20% dari dana apresiasimu juga akan didonasikan bagi penanganan covid-19, melalui program #patunganUntukBerbagiTHR inisiatif OVO, Grab, Tokopedia dengan Benih Baik.</span> <br/>
            <p className='mt-4'> Untuk yang berada di luar negeri dan mau ikut menyawer, Silakan <a target="_blank" style={{color:'red'}} href='https://www.paypal.me/indodancenetwork'>klik disini</a> (tolong cantumkan info penari  dan judul video yang ingin diapresiasi)</p>
            <hr style={{border:'solid 1px black'}} />
            </div>
          {/* Boxes */}
          <div className="thumbnails">
            {showLoading()}
            {videoData.map((vid, i) => (
              <div className="box" key={i}>
                <div className='image fit'> 
                <iframe title='galeri' src={vid.linkyoutube} width="100%" height="200px" frameBorder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
                  
                </div>
                
                  
                
                <div className="inner innerbox color-black ">
                  <h3 className='pb-2 pt-2'>{vid.judul}</h3>
                  
                  <p style={{ textAlign:'justify', paddingBottom:'5px' }}>{vid.konsep.substring(0,100)}... <a style={{color:'red', fontStyle:'italic'}} href={`/video/${vid._id}`}>Read More</a></p>
                  <a href={`/video/${vid._id}`} ><Button className='btn btn-danger btnstyle' style={{width:'100%'}}>SAWER</Button></a>
                </div>
              </div>
            ))}
            
          
          </div>
         
            <div className='buttongalery'> <a href='/galeri' ><button className='btn btn-danger'>Show More Videos</button></a> </div>
        

        </div>
      </div>
      </div>
 
    )
}

export default GaleriHome;
