import React, { useState } from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { login, authenticate, isAuthenticated  } from '../Auth/index'

const LoginPage = () => {
    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        loading: false,
        redirectToReferrer: false
    })

   
    const { email, password, loading, error, redirectToReferrer} = values
    const {user} = isAuthenticated()

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const clickSubmit = (event) =>{
        event.preventDefault()
        setValues({...values, error: false, loading: true})
        login({ email, password})
        .then(data => {
            if(data.error) {
                setValues({
                    ...values, 
                    error: data.error, 
                    loading: false
                })
            } else {
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    })
                })
            }
        })

    }

    const formLogin = () => (
        <>
            <form>
               
                <div className='form-group color-white'>
                <label>Masukan Email</label>
                <input type='text'  className='form-control mb-4' onChange={handleChange('email')} value={email} />
                </div>

                <div className='form-group color-white'>
                <label>Masukan Password</label>
                <input type='password'  className='form-control mb-4' onChange={handleChange('password')} value={password} />
                </div>             

            </form>

            <br/>

            <div style={{textAlign:'center'}}>
                <button onClick={clickSubmit} className='btn btn-danger mb-3' > 
                    <span style={{paddingLeft:'50px', paddingRight:'50px'}}> Login</span> 
                </button>
            </div>
        </>

    )

    const showError = () => (
        <div className='alert alert-danger' style={{display: error ? '' : 'none'}}>
            {error}
        </div>
    )

    const showLoading = () => (
        loading && (
            <div className='alert alert-info' >
                <h2>Loading...</h2>
            </div>
        )
    )

    const redirectUser = () => {
        if(redirectToReferrer) {
           if(user && user.role === 1) {
                return <Redirect to='/admin/dashboard'/>
           } else {
                return <Redirect to='/'/>
           }
        }
        if(isAuthenticated()) {
            return <Redirect to='/'/>
        }
    }

    return (
        <div>
        <NavbarHome/>
            <div id='main'>
               <div className='container text-center color-white' style={{ paddingTop:'50px', paddingBottom:'50px' }}>
                <h2>Silahkan Login Admin</h2>
                <br/>
                {showError()}
                {showLoading()}
                {formLogin()}
                {redirectUser()}

               </div>
            </div>
        <FooterHome/>
        </div>
    )
}

export default LoginPage;