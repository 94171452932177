import React from 'react';
import imglogo2 from '../assets/img/logosaweransm.png'

const ProjectSaweran = () => {

    return (
        <div>
        <section id="main">
            <div className="container">
                <div className='row sectionsawer'>
                    <div className='col-md-12'>
                       <div className=' color-white text-center pb-4'>
                            <img className='logosaweran' src={imglogo2} alt='logosaweran'/>
                       </div>

                       <div className='row carboxx'>
                            <div className='col-md-6'>
                                <div className="kard">
                                    <div className="kard-face">
                                    <h2>Tentang Saweran Online</h2>
                                    <p>Tidak bertemu dan berkumpul, bukan berarti kita tidak bisa berkesenian. Meski covid-19 melanda dunia, kreativitas harus tetap dijaga agar kegembiraan muncul di mana-mana. Saweran Online adalah tempatnya. Penari bisa mementaskan karyanya di panggung digital, penonton bebas memberikan apresiasinya. </p>
                                    <p>
                                    Beragam genre tari ada di Saweran Online: tari tradisional Indonesia, balet, kontemporer, jazz, hiphop, broadway, dan lain-lain. Bukan hanya menikmati tarian, penonton juga dapat mengikuti berbagai kelas tari dan senam tari yang diadakan secara online.
                                    </p>
                                
                                    
                                    </div>
                                </div>
                              
                            </div>

                            <div className='col-md-6'>
                                <div className="kard">
                                <div className="kard-face">
                                    <h2>Mekanisme Seleksi Karya Saweran Online</h2>
                                    <p>Seniman dapat mengirimkan materi karya yang akan ditayangkan di youtube BUDAYASAYA, channel youtube dan IG dari INDONESIA DANCE NETWORK, serta boleh ditayangkan juga melalui channel sanggar / komunitas / sekolah dan pribadi pelaku tarinya. ( Teknis dan persyaratan karya bisa menghubungi bagian Kontak kami ). </p>
                                    <p>
                                    Semua karya yang masuk akan melalui proses seleksi dari Komite Tari serta pihak-pihak yang berkompetensi dan ditunjuk untuk menjaga kesesuaian visi dan misi dari proyek ini.
                                    </p>
                                
                                    
                                </div>
                                </div>
                            </div>
                        

                    
                            <div className='col-md-6'>
                                <div className="kard">
                                    <div className="kard-face">
                                        <h2>Sistem Saweran di Era Digital</h2>
                                        <ul>
                                            <li>Masyarakat yang tertarik memberikan saweran, dapat langsung menyawer dengan mengisi form di landing page ini dan menyelesaikan pembayarannya melalui OVO.</li>
                                            <li>Hasil saweran akan didistribusikan pada pelaku seni yang membuat karya, tentunya setelah dipotong 20% untuk donasi, 5% untuk admin, dan 2,5% PPH 21.</li>
                                            <li>Kami juga akan membuat laporan tertulis secara berkala pada para pelaku seni mengenai siapa penyawernya dan besaran saweran yang diterima.</li>
                                        </ul>
                                    
                                        
                                    </div>
                                </div>
                            </div>

                            
                            <div className='col-md-6'>
                                <div className="kard">
                                    <div className="kard-face">
                                        <h2>Donasi Penanganan Covid-19</h2>
                                        <p>Saweran Online juga merupakan bentuk kepedulian insan dunia tari terhadap penanganan covid-19 yang tentunya membutuhkan banyak dana. Oleh karena itu, sebesar 20% dari hasil saweran tersebut akan diberikan sebagai donasi bagi penanganan covid-19.</p>

                                        
                                    </div>
                                </div>
                            </div>
                    
                        </div>

                        <div className='row carboxxx'>
                            <div className='col-md-12'>
                                <div className="kard2">
                                    <div className="kard2-face">
                                        <h2>Ketentuan Pengiriman Karya</h2>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <p>Bagi teman-teman yang akan mengirimkan
                                                (Boleh karya atau video lama) atau membuat video karya tari atau tutorial tari harap memperhatikan beberapa hal berikut ini :</p>
                                                <span>1. Kamera landscape</span> <br/>
                                                <span>2. Resolusi min. 1920x1080</span> <br/>
                                                <span>3. Format file .mp4 / .mov</span> <br/>
                                                <span>4. Serial dance tutorial durasi maksimal 5 menit untuk 1 seri.</span> <br/>
                                                <span>5. Koreografi solo/pasangan durasi (1 - 3 menit)</span>  <br/>
                                            </div>

                                            <div className='col-md-6 mb-3 '>
                                                <p >Kelengkapan per karya yang harus disertakan (Jika mengirimkan lebih dari satu karya, mohon keterangan karyanya terpisah) :</p>
                                                <span>1. Nama Pribadi / Komunitas :</span> <br/>
                                                <span>2. Judul :</span> <br/>
                                                <span>3. Konsep singkat :</span> <br/>
                                                <span>4. Koreografer :</span> <br/>
                                                <span>5. Penari :</span> <br/>
                                                <span>6. Musik pengiring :</span> <br/>
                                            </div>
                                        </div>
                                        <a target="_blank" href='https://bit.ly/FormKaryaSaweranOnline'>
                                       <button className='btn btn-secondary text-center '>Submit Karya</button>
                                       </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    
                </div>
            </div>
        </section>
        </div>
    )
}

export default ProjectSaweran;

