import React, { useState, useEffect} from 'react';
import '../assets/css/main.css'
import { getCategories, getFilterProduct, listSearch } from '../Auth/apiAdmin'
import { Link } from 'react-router-dom'
import Pagination from '../components/Pagination'
import { Button, Spinner } from 'react-bootstrap'
import Checkbox from './Checkbox';



const GaleriVideo = () => { 
  const [searchData, setSearchData] = useState({
    category: "",
    datasearch: ""
  });
  const [pesan, setPesan] = useState(false);

  // const [videoData, setVideoData] = useState([])
  const [categories, setCategories] = useState([]);
  const [myFilters, setMyFilters] = useState({
    filters: { category: [] }
});
  // const [limit, setLimit] = useState(10);
  // const [skip, setSkip] = useState(0);  
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  
  const [loading, setLoading] = useState(false)

  const init = () => {
    getCategories().then(data => {
        if (data.error) {
            console.log(data.error)
        } else {
            setCategories(data);
        }
    });
};

const loadFilteredResults = newFilters => {
  // console.log(newFilters);
  setLoading(true)
  getFilterProduct(newFilters).then(data => {
    if (data.error) {
      console.log(data.error)
    } else {
        setFilteredResults(data.data);
        setLoading(false)
        // setSize(data.size);
        // setSkip(0);
    }
});
 
};

  useEffect(() => {
    init()
    loadFilteredResults(myFilters.filters)
    // loadVideoData()
  }, [])

  const handleFilters = (filters, filterBy) => {
    // console.log("SHOP", filters, filterBy);

    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    loadFilteredResults(myFilters.filters);
    setMyFilters(newFilters);
    setSearchData({...searchData, category: filters[0] })

  }
  
  const actionsearchData = () => {
    setLoading(true)
    // console.log(searchData.category, searchData.datasearch);
    if (searchData.datasearch) {
      listSearch({ search: searchData.datasearch || undefined, category: searchData.category }).then(
            response => {
                if (response.error) {
                    console.log(response.error);
                } else {
                  setFilteredResults(response);
                  setPesan(true)
                  setLoading(false)

                    
                }
            }
        );
    }
};

  const searchSubmit = e => {
    e.preventDefault();
    actionsearchData();
  };

  

  const handleChange = search => event => {
    setSearchData({ ...searchData, [search]: event.target.value});
    setPesan(false)
  };

  const pesanSearch = (pesan, filteredResults) => {
    if (pesan && filteredResults.length > 0) {
        return `${filteredResults.length} Video ditemukan.`
       
    }
    if (pesan && filteredResults.length < 1) {
        return `0 Video ditemukan, coba cari yang lain.`
    }
  };


  const showLoading = () => (
    loading && (
      <div className='container text-center color-white'>
      <h2>Wait a second, fetching data from server..</h2>
            <Spinner animation="grow" size='lg' variant="primary" />
            <Spinner animation="grow" size='lg' variant="secondary" />
            <Spinner animation="grow" size='lg' variant="success" />
            <Spinner animation="grow" size='lg' variant="danger" />
            <Spinner animation="grow" size='lg' variant="warning" />
            <Spinner animation="grow" size='lg' variant="info" />
      </div>
    )
  )

  const showPaginate = () => (
    !loading && (
      <div className='container'>  
          <Pagination
            
            postsPerPage={postsPerPage}
            totalPosts={filteredResults.length}
            paginate={paginate}
          />
          </div>
    )
  )


    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredResults.slice(indexOfFirstPost, indexOfLastPost);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
      
    <div id="main">

        <div className="inner ">
            <div className='headinggaleri2 color-white container'>
                <h1>VIDEO SAWERAN ONLINE</h1>
                <p>Silahkan tonton video tari gratis di bawah ini. Jika kamu suka, kamu bisa berikan dana apresiasimu.  
                </p>
                <span style={{ fontSize:'15px', textAlign:'justify', fontStyle:'italic', marginBottom:'20px' }}>Sebesar 20% dari dana apresiasimu juga akan didonasikan bagi penanganan covid-19, melalui program #patunganUntukBerbagiTHR inisiatif OVO, Grab, Tokopedia dengan Benih Baik.</span> <br/> 
                <p className='mt-4'> Untuk yang berada di luar negeri dan mau ikut menyawer, Silakan <a target="_blank" style={{color:'red'}} href='https://www.paypal.me/indodancenetwork'>klik disini</a> (tolong cantumkan info penari  dan judul video yang ingin diapresiasi)</p>
                <hr style={{border:'solid 1px white'}} />
            </div>

            <div className='container searcsection'>
            <div className='row'>
             
              <div className='col-md-12 col-sm-12'>
                <form onSubmit={searchSubmit} >
                  <span className="input-group-text searc-input" >
                      
                        
                          <div className="input-group input-group-lg">
                            <input
                                  type="search"
                                  onChange={handleChange("datasearch")}
                                  className="form-control "
                                  placeholder="Search Video"
                                  required
                              />
                          </div>
                          <div
                              className="btn input-group-append"
                              style={{ border: "none" }}
                          >
                              <button className="btn-raised btn-danger searchinput">Search</button>
                          </div>
                        
                      
                  </span>
                </form>
              </div>

              <div className='col-md-12 col-sm-12' >
                <span className="input-group-text searc-input ">
                  <div className='container'>
                  <div className='row color-white' >
                    Filter By Kategori :
                        <Checkbox 
                          categories={categories}
                          handleFilters={filters =>
                            handleFilters(filters, "category")
                          }
                        />
                    </div>
                  </div>
                </span> 
              </div>


            
            </div>
            </div>
          
          <div className='container'>
            <div className='pesansearch color-white text-center'>
              <h2>{pesanSearch(pesan, filteredResults)}</h2>
              
            </div>
          </div>
          
          {showLoading()}
                    
          <div className="thumbnails">

          {currentPosts.map((vid, i) => (
            <div className="box" key={i}>
              <div className='image fit'> 
              <iframe title='galeri' src={vid.linkyoutube} width="100%" height="200px"  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
                
              </div>
              
                
              
              <div className="inner innerbox color-black ">
                <h3 className='pb-2 pt-2'>{vid.judul}</h3>
                
                <p style={{ textAlign:'justify', paddingBottom:'5px' }}>{vid.konsep.substring(0,100)}... <a style={{color:'red', fontStyle:'italic'}} href={`/video/${vid._id}`}>Read More</a></p>
                <a  href={`/video/${vid._id}`} ><Button className='btn btn-danger btnstyle' style={{width:'100%'}} >SAWER</Button></a>
              </div>
            </div>
          ))}
          {showPaginate()}
          
        
        </div>

         
         

        </div>
    </div>
 
    )
}

export default GaleriVideo;