import React from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import AboutHalaman from '../components/AboutHalaman';
import FooterCopyright from '../components/FooterCopyright';

const AboutPage = () => {

    return (
        <div>
            <NavbarHome/>
           <AboutHalaman/>
            <FooterHome/>
            <FooterCopyright/>
        </div>
    )
}

export default AboutPage;