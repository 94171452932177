import React from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import { Button   } from 'react-bootstrap'

import {Link } from 'react-router-dom'

const AdminPage = () => {

    return (
        <div>
            <NavbarHome/>

            <section id="adminheader" >
                <div className="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Selamat Datang Kembali Admin</h2>
                        </div>                                              
                        
                    </div>
                </div>k 
            </section>

                    
            <section id="menuadmin" style={{ paddingTop:'50px', paddingBottom:'100px' }} >

                <div className='container'>
                    <div className='row pt-5'>
                        <div className='col-md-4 mb-3'>
                            <div className='card'>
                                <h2 className='card-header'>Menu Product Video</h2>
                                <ul className='list-group'>
                                <Link to='/admin/dashboard/allvideo' className='nav-link' >
                                    <Button style={{ width:'100%' }} className='btn btn-dark'> Lihat Semua Video</Button>

                                </Link>
                            
                                <Link to='/admin/dashboard/create/video' className='nav-link' >
                                    <Button style={{ width:'100%' }} className='btn btn-success mb-4'> Tambah Video </Button>

                                </Link>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='card'>
                                <h2 className='card-header'>Menu Kategori</h2>
                                <ul className='list-group'>
                                <Link to='/admin/dashboard/categories' className='nav-link' >
                                <Button style={{ width:'100%' }} className='btn btn-primary'> Lihat Semua Kategori</Button>

                                </Link>
                            
                                <Link to='/admin/dashboard/crate/category' className='nav-link' >
                                <Button style={{ width:'100%' }} className='btn btn-danger mb-4'> Tambah Kategori</Button>

                                </Link>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='card'>
                                <h2 className='card-header'>Menu Post Berita</h2>
                                <ul className='list-group'>
                                    
                                        <Link to='#' className='nav-link' >
                                        <Button style={{ width:'100%' }} className='btn btn-warning'> Lihat Semua Berita</Button>

                                        </Link>
                                    
                                        <Link to='#' className='nav-link' >
                                        <Button style={{ width:'100%' }} className='btn btn-secondary mb-4'> Tambah Berita</Button>

                                        </Link>
                                    
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <div className='card'>
                                <h2 className='card-header'>Menu Video Arsip</h2>
                                <ul className='list-group'>
                                    
                                        <Link to='/admin/dashboard/allvideoarsip' className='nav-link' >
                                        <Button style={{ width:'100%' }} className='btn btn-danger'> Lihat Semua Video Arsip</Button>

                                        </Link>
                                    
                                        <Link to='/admin/dashboard/create/videoarsip' className='nav-link' >
                                        <Button style={{ width:'100%' }} className='btn btn-info mb-4'> Tambah Video</Button>

                                        </Link>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>



                </div> 

                                                
               
            </section>
            <FooterHome/>
        </div>
    )
}

export default AdminPage;