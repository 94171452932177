import React, {useState, useEffect} from 'react';
import { getProductVideo, deleteVideoArsip, getVideoArsip } from '../Auth/apiAdmin'
import {Link } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';
import { Button, Table } from 'react-bootstrap'
import Pagination from '../components/Pagination'
import { isAuthenticated } from '../Auth';

const ShowVideoArsip = () => {
    const [datavideo, setDataVideo] = useState([]);
    const [ setError] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
   
    const loaddatavideo = () => {
        getVideoArsip('createdAt').then(data => {
            if(data.error) {
                setError(data.error)
            } else {
                setDataVideo(data)
            }
        })
    }

    const { user, token } = isAuthenticated()

    const removeProduct = productId => {
        deleteVideoArsip(productId, user._id, token ).then(data => {
            if(data.error) {
                console.log(data.error)
            } else {
                loaddatavideo()
            }
            alert('yakin mau delete?')
        })
    }

    useEffect(() => {
        loaddatavideo()
    },[])


    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = datavideo.slice(indexOfFirstPost, indexOfLastPost);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
   
    return (
        <div>
        <NavbarHome/>


            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Semua List Video Arsip</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'fit-content' }} id='main'>
                <div className='container'>
                    <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-sm btn-danger mb-4 mr-2'> Kembali ke Admin </button></Link>
                    <Link to='/admin/dashboard/create/videoarsip' ><button style={{ width:'150px' }}  className='btn btn-sm btn-primary mb-4'> Tambah Video </button></Link>
                    <div className='card mb-5'>
                            <Table striped bordered hover>
                                <thead style={{color:'black'}} >
                                    <tr>
                                        <th>No</th>
                                        <th style={{width:'250px'}}>Judul</th>
                                        <th style={{width:'450px'}}>Konsep</th>
                                        <th>Koreografer</th>
                                        
                                        
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    
                                    {currentPosts.map((videoss, i) => (
                                        
                                        <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{videoss.judul}</td>
                                        <td>{videoss.konsep.substring(0, 200)} ...</td>
                                        <td>{videoss.koreografer}</td>
                                       
                                        
                                        <td>
                                            <Link to={`/admin/dashboard/edit/videoarsip/${videoss._id}`} ><Button style={{width:'100px'}} className='btn btn-info btn-sm mb-2'>Edit</Button></Link>
                                            <Button onClick={() => removeProduct(videoss._id)} style={{width:'100px'}} className='btn btn-danger btn-sm'>Delete</Button>

                                        </td>
                                        </tr>
                                    ))}

                                    
                                </tbody>
                            </Table>
                            
                        </div>
                        
                        <Pagination
                            
                            postsPerPage={postsPerPage}
                            totalPosts={datavideo.length}
                            paginate={paginate}
                        />
                        
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default ShowVideoArsip;