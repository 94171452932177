import React from 'react';
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';
import { Link } from 'react-router-dom';
const SuccessPage = () => {
    
   
    return (
        <div>
        <NavbarHome/>
            <section style={{paddingBottom:'200px', paddingTop:'200px'}} id="main">
                <div class="container">
                <div className='text-center color-white'>
                    <h1 className='color-white'>Sukses Menambahkan Data !</h1>
                    <br/>
                    
                    <Link to='/admin/dashboard/crate/video'><button className='btn btn-success mr-3'>Tambah Video Lagi</button></Link>
                    <Link to='/admin/dashboard'> <button className='btn btn-danger color-white mr-3'>Kembali ke Admin</button></Link>
                    <Link to='/admin/dashboard/allvideo'> <button className='btn btn-info color-white'>Lihat Semua Video</button></Link>
                </div>  
                </div>
            </section>

         
        <FooterHome/>
        
        </div>
    )
}

export default SuccessPage;