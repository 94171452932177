import React, { useState } from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import { Link } from 'react-router-dom';
import { register  } from '../Auth/index'

const RegisterPage = () => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        error: '',
        success: false
    })

    const handleChange = name => event => {
        setValues({...values, error: false, [name]: event.target.value})
    }

    const {name, email, password, success, error} = values

    const clickSubmit = (event) =>{
        event.preventDefault()
        setValues({...values, error: false})
        register({name, email, password})
        .then(data => {
            if(data.error) {
                setValues({
                    ...values, 
                    error: data.error, 
                    success: false
                })
            } else {
                setValues({
                    ...values,
                    name: "",
                    email: "",
                    password: "",
                    error: "",
                    success: true
                })
            }
        })

    }

    const formRegister = () => (
        <>
            <form>
                <div className='form-group color-white '>
                    <label>Masukan Nama </label>
                    <input type='text'  className='form-control mb-4' onChange={handleChange('name')} value={name} />
                </div>

                <div className='form-group color-white'>
                <label>Masukan Email</label>
                <input type='text'  className='form-control mb-4' onChange={handleChange('email')} value={email} />
                </div>

                <div className='form-group color-white'>
                <label>Masukan Password</label>
                <input type='password'  className='form-control mb-4' onChange={handleChange('password')} value={password} />
                </div>             

            </form>

            <br/>

            <div style={{textAlign:'center'}}> 
                <button onClick={clickSubmit} className='btn btn-danger mb-3 color-white' > 
                    <span style={{paddingLeft:'50px', paddingRight:'50px'}}>Register</span> 
                </button>
            </div>
        </>

    )

    const showError = () => (
        <div className='alert alert-danger' style={{display: error ? '' : 'none'}}>
            {error}
        </div>
    )

    const showSuccess = () => (
        <div className='alert alert-success' style={{display: success ? '' : 'none'}}>
            Successfully Signup, Please <Link to='/login/admin'>Log In.</Link>
        </div>
    )

    return (
        <div>
        <NavbarHome/>
            <div id='main'>
               <div className='container text-center color-white' style={{ paddingTop:'50px', paddingBottom:'50px' }}>
                <h2>Register</h2>
                <br/>
                {showError()}
                {showSuccess()}
                {formRegister()}
               </div>
            </div>
        <FooterHome/>
        </div>
    )
}

export default RegisterPage;