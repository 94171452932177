import React, {useState, useEffect} from 'react';
import { getProductVideo, deleteProduct } from '../Auth/apiAdmin'
import {Link } from 'react-router-dom'
import FooterHome from '../components/Footer';
import NavbarHome from '../components/Navbar';
import { Button, Table, Spinner } from 'react-bootstrap'
import Pagination from '../components/Pagination'
import { isAuthenticated } from '../Auth';

const ShowVideo = () => {
    const [datavideo, setDataVideo] = useState([]);
    const [ setError] = useState("");
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
   
    const loaddatavideo = () => {
        setLoading(true)
        getProductVideo('createdAt').then(data => {
            if(data.error) {
                setError(data.error)
            } else {
                setDataVideo(data)
                setLoading(false)

            }
        })
    }

    const { user, token } = isAuthenticated()

    const removeProduct = productId => {
        setLoading(true)
        deleteProduct(productId, user._id, token ).then(data => {
            if(data.error) {
                console.log(data.error)
            } else {
                loaddatavideo()
                setLoading(false)

            }
            alert('yakin mau delete?')
        })
    }

    useEffect(() => {
        loaddatavideo()
    },[])

    const showLoading = () => (
        loading && (
          <div className='container text-center color-black'>
          <h2>Wait a second, fetching data from server..</h2>
                <Spinner animation="grow" size='lg' variant="primary" />
                <Spinner animation="grow" size='lg' variant="secondary" />
                <Spinner animation="grow" size='lg' variant="success" />
                <Spinner animation="grow" size='lg' variant="danger" />
                <Spinner animation="grow" size='lg' variant="warning" />
                <Spinner animation="grow" size='lg' variant="info" />
          </div>
        )
      )

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = datavideo.slice(indexOfFirstPost, indexOfLastPost);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
   
    return (
        <div>
        <NavbarHome/>


            <section id="adminheader">
                <div class="container">
                    <div className='row abot-margin2'>
                        <div className='col-md-9 color-white'>
                            <h2>Semua List Product Video</h2>
                        </div>                                              
                        
                    </div>
                </div>
            </section>

            <div style={{ height:'fit-content' }} id='main'>
                <div className='container'>
                <Link to='/admin/dashboard' ><button style={{ width:'150px' }}  className='btn btn-sm btn-danger mb-4 mr-4'> Kembali ke Admin </button></Link>
                <Link to='/admin/dashboard/create/video' ><button style={{ width:'150px' }}  className='btn btn-sm btn-info mb-4'> Tambah Video </button></Link>
                <div className='card mb-5'>
                            
                            <Table striped bordered hover>
                                <thead style={{color:'black'}} >
                                    <tr>
                                        <th>No</th>
                                        <th style={{width:'200px'}}>Judul</th>
                                        <th style={{width:'300px'}}>Konsep</th>
                                        <th>Koreografer</th>
                                        <th>Category</th>
                                        
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                               
                                    {currentPosts.map((videoss, i) => (
                                        
                                        <tr key={i}>
                                        <td>{ Math.ceil( ((currentPage - 1) * postsPerPage) + i+1)}</td>
                                        <td>{videoss.judul}</td>
                                        <td>{videoss.konsep.substring(0, 200)} ...</td>
                                        <td>{videoss.koreografer}</td>
                                        <td>{videoss.category}</td>
                                        
                                        <td>
                                            <Link to={`/admin/dashboard/edit/video/${videoss._id}`} ><Button style={{width:'100px'}} className='btn btn-info btn-sm mb-2'>Edit</Button></Link>
                                            <Button onClick={() => removeProduct(videoss._id)} style={{width:'100px'}} className='btn btn-danger btn-sm'>Delete</Button>

                                        </td>
                                        </tr>
                                    ))}

                                    
                                </tbody>
                            </Table>
                            {showLoading()} 

                            <Pagination
                                      
                            postsPerPage={postsPerPage}
                            totalPosts={datavideo.length}
                            paginate={paginate}
                        /> 
                    </div>
                         
                </div>
            </div>
        <FooterHome/>
        
        </div>
    )
}

export default ShowVideo;