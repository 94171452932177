import React from 'react';
import budayasaya from '../assets/img/logobs2.png'

const DidukungOLeh = () => {

    return (
        <div>
        <section id="main">
            <div className="container">
            <div className='row mb-4'>
            
                    <div className='col-md-6 '>
                        <div className='color-white text-sponsor pt-1'>
                            <h1>Didukung Oleh</h1>
                        </div>
                    </div> 
                    <div className='col-md-6  '>
                        <img style={{}} src={budayasaya} alt='img' className='imgcenter' />
                    </div>
                   

                
                </div>
            </div>
        </section>
        </div>
    )
}

export default DidukungOLeh;