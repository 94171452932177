import React from 'react';
import '../assets/css/main.css'



const FooterHome = () => {

    return (
        <div>
        <footer id="footer">
               <div className='container'> 
                 <div className='row'>
                        <div className='col-md-6 text-center pb-3'>
                            <h2>Kontak Kami</h2>
                            <br/>
                            <span>Email : indodancenetwork@gmail.com  </span> <br/>
                            <span>Telepon : Burda - 0896-9675-6849 / Sukadi - 0815-1939-8292</span>
                            
                    </div>

                    <div className='col-md-6 text-center'>
                            <h2>Follow Social Media</h2>
                        <br/>
                        <ul className="icons">
                            <li><a href="https://www.instagram.com/indonesiadancenetwork/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
                            <li><a href="https://www.youtube.com/channel/UC1CnjNv_UIZwHdC_-MBgSaw" className="icon fa-youtube"><span className="label">Youtube</span></a></li>
                        </ul>
                        
                    </div>
                 </div>
               </div>
            
        </footer>
        
        </div>
    )
}

export default FooterHome;