import React, { useState } from 'react';
import { Button } from 'react-bootstrap'

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {

  const [ currentPage, setCurrentPage ] = useState(1)
  const handleChange = ( value ) => {
    if(value<1) {
      paginate(1)
      setCurrentPage(1)
    } else if ( Math.ceil(totalPosts/postsPerPage) === (currentPage+1)) {
      paginate(Math.ceil(totalPosts/postsPerPage))
      setCurrentPage(totalPosts/postsPerPage)
    } else if (value > totalPosts / postsPerPage) {
      paginate(Math.round(totalPosts / postsPerPage))
      setCurrentPage(totalPosts / postsPerPage)
    }  else {
      paginate(Math.ceil(value))
      setCurrentPage(value)
    }
  }

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav >
      <ul style={{float:'right'}} className='pagination'>
        {/* {pageNumbers.map(number => (
          <li key={number} className='page-item mr-1 mt-4'>
            
            <button onClick={() => setCurrentPage(number)} className='btn btn-danger'>
              {number} 
            </button>
          </li>
        ))} */}
         
        
        <li className='page-item mr-1 mt-4 color-white'>
          <span className='mr-2'>Page : { Math.ceil(currentPage) } of { Math.ceil(totalPosts/postsPerPage) }</span>
          <Button onClick={() => handleChange(currentPage-1)} variant='danger' className='mr-1 ' size='sm'>Prev</Button>
          <Button onClick={() => handleChange(currentPage+1)} variant='danger' className=' ' size='sm'>Next</Button>
        </li>
      </ul>
      
    </nav>
  );
};

export default Pagination;