import React from 'react';
import FooterHome from '../components/Footer';
import About from '../components/About';
import NavbarHome from '../components/Navbar';
import DidukungOLeh from '../components/DidukungOleh';
import GaleriHome from '../components/GaleriHome';
import SlideHome from '../components/CarouselHome';
import ProjectSaweran from '../components/ProjectSaweran';
import FooterCopyright from '../components/FooterCopyright';
import SliderComponent from '../components/JadwalComponent'


const LandingPage = () => {

    return (

        <div>
            <NavbarHome/>
            <SlideHome/>
            <About/>
            <ProjectSaweran/>
            <GaleriHome/>
            
            <DidukungOLeh/>
            <FooterHome/>
            <FooterCopyright/>

        </div>

    )
}

export default LandingPage;