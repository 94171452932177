import React from "react";
import Slider from "react-slick";
import jadwal1 from '../assets/img/9mei.jpeg'
import jadwal2 from '../assets/img/10mei.jpeg'
import jadwal3 from '../assets/img/11mei.jpeg'


class SliderComponent extends React.Component {
  render() {
    var settings = {
        dots: true,
        speed: 900,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              arrows: false,

            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            }
          }
        ]
      };


    return (

    <div id='sliderjadwal'>
    <div className='contenJadwal' className='container'>

    <h2 className='h2content'>JADWAL TAYANG</h2>
    
        
      <Slider className='sliderimg' {...settings}>


    <div>
    <div className='imgsize'> 
    <img src={jadwal1} />
   </div>
    </div>

    <div>
    <div className='imgsize'> 
    <img src={jadwal2} />
   </div>
    </div>

    <div>
    <div className='imgsize'> 
    <img src={jadwal3} />
   </div>
    </div>

        
      </Slider>
   
    
    </div>
    </div>
    

    );
  }
}
 
export default SliderComponent;