import React from 'react';
import FooterHome from '../components/Footer';
import FooterCopyright from '../components/FooterCopyright';
import NavbarHome from '../components/Navbar';


const ErrorPage = () => {
    return (
        <div>
        <NavbarHome/>

        <div className='errorpage'>
        <h1>Oopss.. Seems like you are lost.</h1>
        <div className="link-container">
        <a  href="/" className="more-link ">Back to Homepage</a>
        </div>
        <section className="error-container">
          <span><span>4</span></span>
          <span>0</span>
          <span><span>4</span></span>
        </section>
       
      </div>

      <FooterHome/>
      <FooterCopyright/>
          
        </div>
    )
}

export default ErrorPage;