import React, { useState, useEffect } from 'react';

const Checkbox = ({categories, handleFilters}) => {
    const [checked, setChecked] = useState([])

    const handleToggle = c => () => {
        // return the first index or -1
        const currentCategoryId = checked.indexOf(c)
        const newCheckedCategoryId = [...checked]
        // if currently checked was not already in checked state > push 
        // else pull/take off
        if(currentCategoryId === -1 ) {
            newCheckedCategoryId.push(c)
        } else {
            newCheckedCategoryId.splice(currentCategoryId, 1)
        }
        // console.log(newCheckedCategoryId)
        setChecked(newCheckedCategoryId)
        handleFilters(newCheckedCategoryId)
    }

    return categories.map((c, i) => (

    <div className='mr-2'>
        <div  key={i}>
            <label htmlFor={c._id} className="color-white ml-2">
                <input 
                    style={{paddingLeft:'5px'}}
                    value={checked.indexOf(c._id === -1)}
                    onChange={handleToggle(c._id)}
                    type="checkbox" 
                    id={c._id} 
                    
                /> {c.namaKategori} 

                
                
            </label>
        </div>
    </div>
            
    
    ));
}

export default Checkbox;