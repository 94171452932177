import React, { useState, useEffect} from 'react';
import '../assets/css/main.css'
import { getVideoArsip } from '../Auth/apiAdmin'
import { Button, Spinner } from 'react-bootstrap'
import Pagination from '../components/Pagination'


const GaleriArsip = () => { 
  const [videoArsip, setVideoArsip] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const [loading, setLoading] = useState(false)

  const loadVideoData = () => {
    setLoading(true)
    getVideoArsip('createdAt').then(data => {
        if(data.error) {
           console.log(data.error)
        } else {
          setVideoArsip(data)
          setLoading(false)

        }
    })
  }

  useEffect(() => {
    loadVideoData()
  }, [])


  const showLoading = () => (
    loading && (
      <div className='container text-center color-white'>
      <h2>Wait a second, fetching data from server..</h2>
            <Spinner animation="grow" size='lg' variant="primary" />
            <Spinner animation="grow" size='lg' variant="secondary" />
            <Spinner animation="grow" size='lg' variant="success" />
            <Spinner animation="grow" size='lg' variant="danger" />
            <Spinner animation="grow" size='lg' variant="warning" />
            <Spinner animation="grow" size='lg' variant="info" />
      </div>
    )
  )
  
    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = videoArsip.slice(indexOfFirstPost, indexOfLastPost);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);


    return (
      
        <div id="main">
        
        <div className="inner">
            <div className='headinggaleri2 color-white mb-4'>
                <h1>GALERI VIDEO</h1>
                <p>Tonton koleksi video - video dari Indonesia Dance Network  
                </p>
            
            </div>
          {/* Boxes */}
          <div className="thumbnails">
            {showLoading()}
            {currentPosts.map((vid, i) => (
              <div className="box" key={i}>
                <div className='image fit'> 
                <iframe title='galeri' src={vid.linkyoutube} width="100%" height="200px" frameBorder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
                  
                </div>
                
                  
                
                <div className="inner innerbox color-black ">
                  <h3 className='pb-2 pt-2'>{vid.judul}</h3>
                  
                  <p style={{ textAlign:'justify', paddingBottom:'5px' }}>{vid.konsep.substring(0,100)}... </p>
                  <a href={`/videoarsip/${vid._id}`} ><Button className='btn btn-dark btnstyle' style={{width:'100%'}}>READ MORE</Button></a>
                </div>
              </div>
            ))}
            
          
         <div className='container'>
         <Pagination
                            
         postsPerPage={postsPerPage}
         totalPosts={videoArsip.length}
         paginate={paginate}
     />
         </div>
          </div>
         
        

        </div>
      
      </div>
 
    )
}

export default GaleriArsip;
