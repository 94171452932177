import React from 'react';
import NavbarHome from '../components/Navbar';
import FooterHome from '../components/Footer';
import GaleriVideo from '../components/GaleriVIdeo';
import FooterCopyright from '../components/FooterCopyright';

const GaleryPage = () => {

    return (
        <div>
           <NavbarHome/>
           <GaleriVideo/>
           <FooterHome/>
           <FooterCopyright/>
        </div>
    )
}

export default GaleryPage;
